<template>
    <div>
        <h1>Pricing</h1>
    </div>
</template>

<script>
export default {
    name: 'Pricing',
    
}
</script>

<style>

</style>
